import React, { useState, useEffect, useRef, useMemo } from "react";
import { createConsumer } from "@rails/actioncable";
import ChatInput from "@/components/ais/asistant_chat/ChatInput";
import MessageBubble from "@/components/ais/asistant_chat/MessageBubble";
import Header from "@/components/users/ais/chat/Header";
import { Ai } from "@/interfaces/ai";

interface Message {
  content: string;
  type: "text" | "code";
  sender: "user" | "ai";
}

interface ChatAppProps {
  cableEndpoint: string; // 例: "ws://localhost:3000/cable"
  ai: Ai;
  userToken: string;
}

const ChatApp: React.FC<ChatAppProps> = ({ cableEndpoint, ai, userToken }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [streamingMessage, setStreamingMessage] = useState("");
  // streamingMessageの最新値を参照するためのrefを追加
  const streamingMessageRef = useRef("");
  const [isStreaming, setIsStreaming] = useState(false);
  const cable = useMemo(() => createConsumer(cableEndpoint), [cableEndpoint]);
  const [subscription, setSubscription] = useState<any>(null);

  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const [channelToken, setChannelToken] = useState(generateRandomString(20));

  useEffect(() => {
    const sub = cable.subscriptions.create(
      { channel: "PrivateChatAssistantChannel", token: channelToken },
      {
        connected: () => {
          console.log("WebSocket connected");
        },
        received: (res: any) => {
          if (res.error) {
            setIsStreaming(false);
            alert(res.error);
            setStreamingMessage((prev) => {
              const updated = prev + "申し訳ございません、問題が発生しました";
              streamingMessageRef.current = updated;
              return updated;
            });
            if (res.errorCode === "upgrade") {
              window.location.reload();
            }
          } else if (res.status) {
            if (res.status === "finish") {
              setIsStreaming(false);
              const finalMessage = streamingMessageRef.current;
              // assistantのメッセージが空でなければmessagesに追加する
              if (finalMessage.trim() !== "") {
                setMessages((prev) => [
                  ...prev,
                  { content: finalMessage, type: "text", sender: "ai" },
                ]);
              }
              // streamingMessageとそのrefをリセット
              setStreamingMessage("");
              streamingMessageRef.current = "";
            }
          } else if (res.data) {
            setStreamingMessage((prev) => {
              const updated = prev + res.data;
              streamingMessageRef.current = updated;
              return updated;
            });
          }
        },
      }
    );
    setSubscription(sub);

    return () => {
      if (sub) sub.unsubscribe();
    };
  }, [cable, channelToken]);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [autoScroll, setAutoScroll] = useState(true);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    if (!containerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    // ユーザーが下端から50px以上上にスクロールした（手動スクロールアップした）場合は自動スクロールを停止
    // 逆に、下端に近づいた場合（50px以内）には自動スクロールを再開する
    if (scrollHeight - (scrollTop + clientHeight) > 50) {
      setAutoScroll(false);
    } else {
      setAutoScroll(true);
    }
  };

  useEffect(() => {
    if (autoScroll) {
      scrollToBottom();
    }
  }, [messages, autoScroll]);

  const handleSubmit = (message: string) => {
    if (!message.trim()) return;

    // ユーザーメッセージを追加
    setMessages((prev) => [
      ...prev,
      { content: message, type: "text", sender: "user" },
    ]);

    // 会話履歴構築時に、空のassistantメッセージは除外する
    const conversationHistory = messages
      .filter((msg) => !(msg.sender === "ai" && msg.content.trim() === ""))
      .map((msg) => ({
        role: msg.sender === "user" ? "user" : "assistant",
        content: msg.content,
      }));
    conversationHistory.push({ role: "user", content: message });

    setIsStreaming(true);

    if (subscription) {
      subscription.perform("stream_message", {
        ai_slug: ai.slug,
        form_data: message,
        token: channelToken,
        user_token: userToken,
        conversation_history: conversationHistory,
      });
    }
  };

  return (
    <div className="min-h-[50vh] flex flex-col">
      <Header
        ai={ai}
        favorites={[]}
        addFavorite={() => {}}
        removeFavorite={() => {}}
      />
      <div className="flex-1 max-w-4xl w-full mx-auto p-4 flex flex-col">
        <div
          ref={containerRef}
          onScroll={handleScroll}
          className="flex-1 overflow-y-auto space-y-4 mb-4 mb-[120px]"
        >
          {messages.map((msg, index) => (
            <MessageBubble
              key={index}
              content={msg.content}
              isUser={msg.sender === "user"}
            />
          ))}
          {isStreaming && streamingMessage.trim() === "" ? (
            // 生成が始まるまでの間、左寄せでloadingアニメーションを表示
            <div className="flex justify-start items-center">
              <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-blue-500"></div>
            </div>
          ) : (
            <MessageBubble content={streamingMessage} isUser={false} isTyping />
          )}
          <div ref={messagesEndRef} />
        </div>
        <ChatInput onSubmit={handleSubmit} disabled={isStreaming} />
      </div>
    </div>
  );
};

export default ChatApp;
