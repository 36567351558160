import React, { useEffect, useState, useMemo } from "react";
import { MovieAi } from "../../../../interfaces/movie_ai";
import { InputField } from "../../../../interfaces/input_field";
import PromptSettingForm from "../../../users/ais/movie/PromptSettingForm";
import QueuedLoader from "@/components/users/ais/movie/QueuedLoader";
import { createConsumer } from "@rails/actioncable";
import { toast } from "react-toastify";
import Header from "../../../users/ais/movie/Header"; // Headerを追加
import { User } from "@/interfaces/user";
import { UserPlan } from "@/interfaces/user_plan";
import NavBar from "../../../partials/navs/Bar";
import UpgradeAlert from "../../../../components/partials/modals/UpgradeAlert";

type Props = {
  movieAi: MovieAi;
  inputFields: InputField[];
  user: User;
  userPlan: UserPlan;
};

const UsersMovieAisShow: React.FC<Props> = (props) => {
  const [streaming, setStreaming] = useState(false);
  const [logs, setLogs] = useState<string>("");
  const [progress, setProgress] = useState<string>("0");
  const [status, setStatus] = useState<string>("");
  const [movieUrl, setMovieUrl] = useState<string>("");
  const [upgradeModalOpen, setUpgradeModalOpen] = useState<boolean>(false);
  const [currentMovieGenerateDuration, setCurrentMovieGenerateDuration] =
    useState<number>(props.user.currentMovieGenerateDuration);

  const cable = useMemo(() => createConsumer(), []);

  const generateRandomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }

    return result;
  };

  const onSubmitPromptSettingForm = async (data: any) => {
    const token = generateRandomString(20);
    data.movie_ai_slug = props.movieAi.slug;
    setStreaming(true);

    if (currentMovieGenerateDuration >= props.user.movieGenerateLimit) {
      setUpgradeModalOpen(true);
      return;
    }

    const channel = cable.subscriptions.create(
      { channel: "PrivateMovieChannel", token: token },
      {
        connected: () => {
          console.log("WebSocket connected");
          channel.perform("generate", { form_data: data, token: token });
        },
        disconnected: () => {
          console.log("WebSocket disconnected");
        },
        received: (res) => {
          if (res.error) {
            toast.error(res.error);
            setStreaming(false);
          }
          if (res.status) {
            setStatus(res.status);
            if (res.status == "succeeded") {
              setMovieUrl(res.data);
              setStreaming(false);
            } else if (res.status == "started") {
              setCurrentMovieGenerateDuration((prevDuration) => {
                return prevDuration + parseInt(data.duration, 10);
              });
            }
          }
        },
      }
    );
  };

  const cancelStreaming = () => {
    setStreaming(false);
    // WebSocketの切断処理をここに追加する
  };

  return (
    <>
      <div className="flex-1 justify-between flex flex-col pb-32">
        <Header ai={props.movieAi} /> {/* Headerを追加 */}
        <div className="w-full inline-block mb-6 mt-6">
          <NavBar
            count={currentMovieGenerateDuration}
            setCount={setCurrentMovieGenerateDuration}
            limit={props.user.movieGenerateLimit}
            isFree={!props.user.isPaidMovie}
            unit={`秒`}
            resetAt={props.userPlan?.nextPlanUpdatedAt}
          />
        </div>
        <div className="shadow sm:overflow-hidden sm:rounded-md border mt-6">
          <PromptSettingForm
            submit={(datas) => onSubmitPromptSettingForm(datas)}
            inputFields={props.inputFields}
            streaming={streaming}
            cancelStreaming={cancelStreaming}
            remainGenerateCap={100}
            setSwitchModalOpen={() => {
              return false;
            }}
          />
        </div>
        <QueuedLoader
          logs={logs}
          status={status}
          movieUrl={movieUrl}
          progress={progress}
        />
      </div>
      <UpgradeAlert
        open={upgradeModalOpen}
        setOpen={setUpgradeModalOpen}
        title={`動画生成の上限に達しました`}
        message={`現状のプランでは動画生成が「${props.user.movieGenerateLimit}秒」までとなっております。以下のボタンから動画生成プレミアムプラン加入ページに移動し、アップグレードしてください`}
        redirectLink={`/users/plans/movies`}
        cta={`動画生成プレミアムプランを見る`}
      />
    </>
  );
};

export default UsersMovieAisShow;
