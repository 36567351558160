import React, { useMemo } from "react";
import CodeBlock from "./CodeBlock";

interface MessageBubbleProps {
  content: string;
  isTyping?: boolean;
  isUser?: boolean;
}

const MessageBubble = ({ content, isTyping, isUser }: MessageBubbleProps) => {
  // 親コンポーネントでWebSocketにより、contentが1文字ずつ追加・更新されるため、
  // content更新時に毎回分割処理を実行し、最新の内容をレンダリングします。
  const parsedContent = useMemo(() => {
    // 三連バッククォート（```）で文字列を分割します
    const segments = content.split(/```/g);
    return segments.map((segment, index) => {
      // 奇数番目はコードブロックとして扱います
      if (index % 2 === 1) {
        const lines = segment.split("\n");
        let code = segment;
        // 最初の行がプログラミング言語指定（英数字のみ）の場合は除去
        if (lines.length > 1 && /^[a-zA-Z0-9]+$/.test(lines[0].trim())) {
          code = lines.slice(1).join("\n");
        }
        return <CodeBlock key={index} code={code} />;
      }
      // 偶数番目は通常テキストとして扱い、改行文字で改行し、
      // また「###」などのマークアップがある場合は、対応するHTMLヘッダーに変換します
      if (segment === "") return null;
      const lines = segment.split("\n");
      return (
        <React.Fragment key={index}>
          {lines.map((line, i) => {
            const headerMatch = line.match(/^(#{1,6})\s+(.*)$/);
            if (headerMatch) {
              const HeaderTag =
                `h${headerMatch[1].length}` as keyof JSX.IntrinsicElements;
              return (
                <HeaderTag key={i} className="leading-relaxed">
                  {headerMatch[2]}
                </HeaderTag>
              );
            }
            return (
              <span key={i} className="leading-relaxed">
                {line}
                {i < lines.length - 1 && <br />}
              </span>
            );
          })}
        </React.Fragment>
      );
    });
  }, [content]);

  return (
    <div
      className={`flex ${isTyping ? "animate-fade-in" : ""} ${
        isUser ? "justify-end" : "justify-start"
      }`}
    >
      <div
        className={`max-w-3xl ${
          isUser ? "bg-blue-500 text-white rounded-lg px-4 py-2" : ""
        }`}
      >
        {parsedContent}
      </div>
    </div>
  );
};

export default MessageBubble;
