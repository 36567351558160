import React, { useState, useRef, useEffect } from "react";
import { ChevronUp, ChevronRight } from "lucide-react";

interface QueuedLoaderProps {
  onCancel?: () => void;
  logs: string;
  status: string;
  progress: string;
  movieUrl: string;
}

import {
  InformationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";

const QueuedLoader = ({
  logs,
  status,
  progress,
  movieUrl,
}: QueuedLoaderProps) => {
  const [logsVisible, setLogsVisible] = useState(false);
  const logsContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (logsContainerRef.current) {
      logsContainerRef.current.scrollTop =
        logsContainerRef.current.scrollHeight;
    }
  }, [logs]);

  if (status == "") {
    return <></>;
  }

  return (
    <div className="flex flex-col items-start gap-6 p-4 font-sans">
      <div
        className={`rounded-md p-4 w-full ${
          status === "succeeded" ? "bg-green-50" : "bg-blue-50"
        }`}
      >
        <div className="flex">
          <div className="shrink-0">
            {status !== "succeeded" && (
              <InformationCircleIcon
                aria-hidden="true"
                className="size-5 text-blue-400"
              />
            )}
            {status === "succeeded" && (
              <CheckCircleIcon
                aria-hidden="true"
                className="size-5 text-green-400"
              />
            )}
          </div>
          <div className="ml-3 w-full">
            {status === "uploading" && (
              <>
                <h3 className="text-sm font-bold text-blue-800">初期化処理</h3>
                <div className="mt-2 text-sm text-blue-700">
                  <p>動画構成作成中。数分お待ちください。</p>
                </div>
              </>
            )}

            {status === "started" && (
              <>
                <h3 className="text-sm font-bold text-blue-800">処理開始</h3>
                <div className="mt-2 text-sm text-blue-700">
                  <p>処理を開始します。</p>
                </div>
              </>
            )}
            {status === "dreaming" && (
              <>
                <h3 className="text-sm font-bold text-blue-800">処理中</h3>
                <div className="mt-2 text-sm text-blue-700">
                  <p>処理が開始されました。完了までお待ち下さい...</p>
                </div>
              </>
            )}
            {status === "succeeded" && (
              <>
                <h3 className="text-sm font-bold text-green-800">完了</h3>
                <div className="mt-2 text-sm text-green-700">
                  <p>
                    動画生成が完了しました。ページ下部から動画をダウンロードしてください
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* <button
        onClick={onCancel}
        className="px-8 py-2 border border-[#000] hover:bg-gray-50 transition-colors text-base"
      >
        Cancel
      </button> */}
      {status === "processing" && (
        <>
          <button
            onClick={() => setLogsVisible(!logsVisible)}
            className="flex items-center gap-2 text-[#666] hover:text-[#444] transition-colors text-xs"
          >
            {logsVisible ? <ChevronUp size={16} /> : <ChevronRight size={16} />}
            <span>{logsVisible ? "ログを非表示" : "ログを表示"}</span>
          </button>

          {logsVisible && (
            <div className="w-full flex flex-col">
              <div
                ref={logsContainerRef}
                className="w-full h-[400px] bg-[#f5f5f5] rounded-sm p-4 font-mono text-sm overflow-auto whitespace-pre-wrap break-words"
              >
                {logs}
              </div>
            </div>
          )}
        </>
      )}
      {status === "succeeded" && (
        <div className="w-full flex flex-col mt-4">
          <video
            src={movieUrl}
            controls
            autoPlay
            className="w-full h-auto rounded-sm"
          />
        </div>
      )}
    </div>
  );
};

export default QueuedLoader;
